<template>
  <div style="height: inherit">
    <cases-form
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      @refetch-data="refetchData"
      @error-data="showError"
    />
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchQuery"
              placeholder="Buscar por nombre y/o apellidos del estudiante; recuerde pulsar ENTER"
              @keyup.enter="updateQuery"
            />
          </b-input-group>
        </div>
      </div>

      <div
        v-if="isBusy"
        class="text-center text-primary my-2"
      >
        <b-spinner
          variant="primary"
          class="align-middle"
        />
        <strong class="ml-1">Cargando...</strong>
      </div>

      <!-- Emails List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
        style="background: white;"
      >
        <ul class="email-media-list">
          <b-media
            v-for="student in students"
            :key="student.idStudent"
            tag="li"
            no-body
            class="mail-read"
            style="background: white;"
          >

            <b-media-aside class="media-left mr-50">
              <b-avatar
                rounded
                class="avatar"
                size="70"
                variant="primary"
                :src="undefined"
              />
            </b-media-aside>

            <b-media-body>
              <div class="mail-details">
                <div class="mail-items">
                  <h6 class="mb-25">
                    {{ student.firstName }} {{ student.lastNameOne }} {{ student.lastNameTwo }} ({{ getAge(student.birthdate) }})
                  </h6>
                  <b-badge
                    variant="info"
                    class="mr-50"
                  >
                    {{ student.educationalInstitution.name }}
                  </b-badge>
                  <b-badge
                    variant="light-info"
                    class="mr-50"
                  >
                    {{ student.degree }} {{ student.section }}
                  </b-badge>
                </div>
                <div class="mail-meta-item">
                  <b-button-toolbar justify>
                    <b-button-group size="sm">
                      <b-button
                        v-b-tooltip.hover.top="'Tratamientos Terapéuticos'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="info"
                        @click="getTratamiento(student)"
                      >
                        <feather-icon icon="ActivityIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="'Seguimiento'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="success"
                        @click="openModalIncidence(student)"
                      >
                        <feather-icon icon="ClipboardIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="'Editar'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary"
                        @click="editRegister(student)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="'Eliminar'"
                        variant="danger"
                        class="btn-cart move-cart"
                        @click="deleteRegister(student)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-50"
                        />
                      </b-button>
                    </b-button-group>
                  </b-button-toolbar>
                </div>
              </div>

              <div class="mail-message">
                <span class="mail-date align-middle">
                  Info Tutor:
                </span>
                <span
                  class="mail-date align-middle"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="14"
                    class="text-secondary"
                  />
                  {{ capitalizeWords(`${student.tutor.firstName} ${student.tutor.lastNameOne}`) }}
                </span>
                <span class="mail-date align-middle">
                  <feather-icon
                    icon="PhoneCallIcon"
                    size="14"
                    class="text-secondary"
                  />
                  {{ student.tutor.phone }}
                </span>
                <span class="mail-date align-middle">
                  <feather-icon
                    icon="MailIcon"
                    size="14"
                    class="text-secondary"
                  />
                  {{ student.tutor.email }}
                </span>
              </div>
            </b-media-body>
          </b-media>
        </ul>
      </vue-perfect-scrollbar>

      <div class="app-action">
        <div class="action-left">
          <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
        </div>
        <b-pagination
          v-model="page"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          align="center"
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <cases-left-sidebar
        :sexos="sexos"
        :insitutuciones-educativas="insitutucionesEducativas"
        :show-email-details="showEmailDetails"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
        @open-form="openForm"
        @apply-filter="applyFilter"
      />
    </portal>

    <incidence-list
      ref="refModalIncidence"
      :data-info="dataIncidence"
    />

    <treatment-list
      ref="refModalTreatment"
      :data-info="dataTreatment"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import {
  ref, watch,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BButton, VBTooltip, BMedia, BMediaAside, BMediaBody, BAvatar, BButtonToolbar, BButtonGroup, BBadge, BPagination, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { useNotify } from '@/helpers/toast'
import { capitalizeWords } from '@/helpers/strings'
import { getAge } from '@/helpers/dates'
import CasesLeftSidebar from './CasesLeftSidebar.vue'
import CasesForm from './CasesForm.vue'
import IncidenceList from './incidence/IncidenceList.vue'
import TreatmentList from './treatment/TreatmentList.vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BBadge,
    BPagination,
    BSpinner,

    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    CasesLeftSidebar,
    CasesForm,
    IncidenceList,
    TreatmentList,
    // ModalMultimedia,
    // ModalQr,
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const sexos = ref(['MASCULINO', 'FEMENINO'])
    const insitutucionesEducativas = ref([])

    const dataEdit = ref({})
    const dataInfo = ref({})
    const typeForm = ref('')

    const totalRows = ref(0)
    const totalItems = ref(0)
    const totalPages = ref(0)
    const perPage = ref(10)
    const page = ref(1)
    const from = ref(0)
    const to = ref(0)
    const of = ref(0)
    const isBusy = ref(false)
    const pageOptions = ref([10, 20, 50])
    const showEmailDetails = ref(false)
    const ftIdEducationalInstitution = ref('-1')
    const ftStateEvolution = ref('')
    const ftSex = ref('')
    const isToggleSidebar = ref(false)
    const formType = ref('')
    const students = ref([])

    const searchQuery = ref('')

    const dataIncidence = ref({})
    const refModalIncidence = ref(null)

    const dataTreatment = ref({})
    const refModalTreatment = ref(null)

    const openModalIncidence = data => {
      dataIncidence.value.idStudent = data.idStudent
      dataIncidence.value.titleModal = `SEGUIMIENTO DE CASO: ${data.firstName.toUpperCase()} ${data.lastNameOne.toUpperCase()}`
      refModalIncidence.value.openModal()
    }

    const editRegister = data => {
      formType.value = 'edit'
      isToggleSidebar.value = true
      dataEdit.value = data
    }

    const showNotify = data => {
      notify(data.title, data.message, data.type)
    }

    const getEducationalsIntitution = async () => {
      await store.dispatch('ppptcd/EDUCATIONAL_INSTITUTION_FIND_ALL', {
        type: 'list',
        query: '',
        page: 1,
        limit: -1,
        sortBy: 'name%7CASC',
      })
        .then(response => {
          if (response) {
            insitutucionesEducativas.value = response
          }
        })
    }

    const showError = () => {
      console.log('error aca')
    }

    const getTratamiento = data => { // REGISTRO_DERIVACION_AUTORIZADA
      const isDerivado = data.incidences.find(incidence => incidence.type === 'REGISTRO_DERIVACION_AUTORIZADA')
      if (isDerivado) {
        // notify('Informativo', 'La DIRESA puede registrar intervenciones del caso.', 'success')

        dataTreatment.value.idStudent = data.idStudent
        dataTreatment.value.titleModal = `INTERVENCIONES TERAPÉUTICAS - CASO: ${data.firstName.toUpperCase()} ${data.lastNameOne.toUpperCase()}`
        refModalTreatment.value.openModal()
      } else {
        notify('Advertencia', 'El caso aún no ha sido derivado a la DIRESA.', 'warning')
      }
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const listItems = async () => {
      isBusy.value = true
      await store.dispatch('ppptcd/STUDENT_FIND_ALL', {
        query: searchQuery.value !== undefined ? searchQuery.value : '',
        page: page.value,
        limit: perPage.value,
        sortBy: 'idStudent%7CDESC',
        idEducationalInstitution: ftIdEducationalInstitution.value,
        stateEvolution: ftStateEvolution.value,
        sex: ftSex.value,
        type: 'grilla',
      })
        .then(response => {
          if (response) {
            totalRows.value = response.totalRows
            totalItems.value = response.totalItems
            totalPages.value = response.totalPage
            from.value = perPage.value * (page.value - 1) + (totalRows.value ? 1 : 0)
            to.value = perPage.value * (page.value - 1) + totalRows.value
            of.value = totalItems.value
            students.value = response.items
          }
        })
      isBusy.value = false
    }

    const updateQuery = () => {
      listItems()
    }

    const applyFilter = filters => {
      if (filters.value.idsEEII !== null && filters.value.idsEEII.length > 0) {
        ftIdEducationalInstitution.value = filters.value.idsEEII.join()
      } else {
        ftIdEducationalInstitution.value = '-1'
      }
      if (filters.value.idsSexos !== null && filters.value.idsSexos.length > 0 && filters.value.idsSexos.length < 2) {
        ftSex.value = filters.value.idsSexos.join()
      } else {
        ftSex.value = ''
      }
      listItems()
    }

    const refetchData = response => {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      showNotify({ title: 'Operación Exitosa', message, type: 'success' })
      listItems()
    }

    watch([page], () => {
      listItems()
    })

    getEducationalsIntitution()
    listItems()

    const openForm = type => {
      formType.value = type
      isToggleSidebar.value = true
    }

    const editEmpresa = item => {
      dataEdit.value = item
      showEmailDetails.value = true
    }

    const deleteRegister = item => {
      Vue.swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('ppptcd/STUDENT_DELETE', {
              id: item.idStudent,
            })
            .then(() => {
              notify('Operación Exitosa', 'El proceso se ha ejecutado satisfactoriamente.', 'success')
              listItems()
            })
            .catch(error => {
              let errorMessage = 'Intentelo en otro momento.'
              if (error.response) {
                errorMessage = error.response.data.message
              }
              notify('Ha ocurrido un error', errorMessage, 'danger')
            })
        }
      })
    }

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      sexos,
      insitutucionesEducativas,
      isToggleSidebar,
      formType,
      openForm,
      showError,
      refetchData,
      editEmpresa,
      dataEdit,
      dataInfo,
      typeForm,
      perfectScrollbarSettings,
      showEmailDetails,
      searchQuery,
      filterTags,
      formatDateToMonthShort,
      mqShallShowLeftSidebar,
      totalItems,
      perPage,
      page,
      totalPages,
      totalRows,
      from,
      of,
      to,
      isBusy,
      pageOptions,
      showNotify,
      applyFilter,
      deleteRegister,
      updateQuery,
      students,
      getTratamiento,
      openModalIncidence,
      dataIncidence,
      refModalIncidence,
      dataTreatment,
      refModalTreatment,
      capitalizeWords,
      getAge,
      editRegister,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";

.per-page-selector {
  width: 90px;
}

.email-application .content-area-wrapper .email-user-list .email-media-list li .avatar img {
  width: 100% !important;
  height: 100% !important;
}

.email-application .content-area-wrapper .email-user-list .email-media-list li .avatar {
  margin-bottom: 0px !important;
}

.email-application .app-action {
    padding: 0.6rem 1.5rem;
}
</style>
